import React, {Component} from 'react';
import {Link} from 'react-scroll';
import Sticky from 'react-stickynode';
import {NavLink} from "react-router-dom";

export default class NavBar extends Component {
  render() {
    const {mClass, nClass, cClass, slogo, hbtnClass} = this.props;
    return (
        <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
          <header className="header_area">
            <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
              <div className={`container ${cClass}`}>
                {/*<Link className={`navbar-brand ${slogo}`} to="/">*/}
                  {/*<img src={require("../img/logo2.png")} alt=""/>*/}
                  {/*<img src={require("../img/logo.png")} alt="logo"/>*/}
                  <img src={require("../img/logo-horizontal-nyko2.png")} alt="logo" height="50"/>
                {/*</Link>*/}
                <button className="navbar-toggler collapsed" type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                </button>

                <div className="collapse navbar-collapse"
                     id="navbarSupportedContent">
                  <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                    <li className="nav-item">
                      <Link className="nav-link" activeClass="active" to="home"
                            spy={true} smooth={true} offset={0}
                            duration={500}>Home</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" activeClass="active"
                            to="services" spy={true} smooth={true} offset={0}
                            duration={1000}>Services</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" activeClass="active"
                            to="about" spy={true} smooth={true} offset={0}
                            duration={1000}>About</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" activeClass="active" to="contact"
                            spy={true} smooth={true} offset={-90}
                            duration={1000}>Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </header>
        </Sticky>
    );
  }
}
